import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;

  thead tr {
    border-bottom: 1px solid #ccc;
  }

  thead td {
    padding: 8px;
  }

  tbody tr {
    border-bottom: 1px solid #ccc;
  }

  tbody td {
    padding: 8px;
  }
`;

interface ContainerProps {
  $isBorderColor?: boolean;
  $isBoxShadow?: boolean;
}

const Container = styled.div.attrs<ContainerProps>((props) => ({
  $isBorderColor: props.$isBorderColor ?? true,
  $isBoxShadow: props.$isBoxShadow ?? true
}))<ContainerProps>`
  width: 100%;
  background: white;
  padding: 12px;
  border-radius: 4px;
  border-top: ${(props) => (props.$isBorderColor ? '5px solid rgb(0, 131, 80)' : '')};
  box-shadow: ${(props) =>
    props.$isBoxShadow
      ? `0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12)`
      : ''};
`;

const Title = styled.div`
  text-align: center;
  font-weight: bold;
  padding: 8px;
  border: 2px solid ${(props) => props.theme.colors.primary};
  border-radius: 8px;
`;

const Line = styled.div`
  height: 5px;
  width: 100%;
  border: 2px solid;
`;

export { Container, Line, Table, Title };
