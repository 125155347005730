import type { IOptionsProps } from 'components/menu';
import type { PurchaseStatus } from 'query';

type Variant = 'text' | 'contained' | 'outlined';
type TableBodyData = string | number | boolean;
export type TableBodyType = 'EARRING' | 'ANIMAL' | 'FOOD';

export type HighlightedStatusProps = 'PENDING' | 'RECEIVED' | 'STORED' | 'STORED_ERRING' | 'REALIZED';

export enum HighlightedStatus {
  PENDING = 'Pendente',
  RECEIVED = 'Recebido',
  STORED = 'Finalizado',
  STORED_ERRING = 'Disponível',
  REALIZED = 'Realizado'
}

export type ISearchOptionsSubmitProps = {
  searchOption: string;
  search: string;
};

export interface IFullTableActions {
  label: string;
  variant?: Variant;
  alwaysVisible?: boolean;
  onClick: (id: string) => void;
}

// Faz de tudo e tenta não mudar o type disso
export interface ITableBody {
  id: string;
  status?: PurchaseStatus;
  type?: TableBodyType;
  data: TableBodyData[];
}

export interface IFullTableProps {
  title: string;
  tableHead: string[];
  tableBody: ITableBody[];
  actions?: IFullTableActions[];
  loading?: boolean;
  filters?: IOptionsProps;
  search?: ISearchOptionsProps;
  page?: number;

  onDelete?: (id: string[]) => void;
  onPurchaseStatus?: (id: string, status?: PurchaseStatus) => void;
  positionsHighlighted?: number[];
  onNextPage?: (page: number) => void;
  onPreviousPage?: (page: number) => void;
}

export type ISearchOptionsProps = {
  modalTitle: string;
  searchOption?: {
    title: string;
    data: {
      label: string;
      value: string;
    }[];
  };
  onSubmit: (data: ISearchOptionsSubmitProps) => void;
};
