import type { ITableBody } from '@generalComponents';
import { ReactNode, useReducer } from 'react';
import { createContext } from 'use-context-selector';

type TableProviderProps = {
  tableBody: ITableBody[];
  children: ReactNode;
};

interface InitialStateProps {
  selected: string[];
  selectedIds: string[];
  page: number;
  rowsPerPage: number;
  contextMenuOpened: boolean;
}

const initialState: InitialStateProps = {
  selected: [],
  selectedIds: [],
  page: 0,
  rowsPerPage: 100,
  contextMenuOpened: false
};

type Action =
  | {
      type: 'SET_ROW_CLICK';
      selected: InitialStateProps['selected'];
      selectedIds: InitialStateProps['selectedIds'];
    }
  | {
      type: 'SET_PAGE';
      page: number;
    }
  | {
      type: 'SET_ROWS_PER_PAGE';
      page: number;
      rowsPerPage: number;
    };

type TableContextData = InitialStateProps & {
  handleRowClick: (data: ITableBody, _id: number) => void;
  handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePage: (page: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearSelections: () => void;
};

const reducer = (state: InitialStateProps, action: Action) => {
  switch (action.type) {
    case 'SET_ROW_CLICK':
      return { ...state, selected: action.selected, selectedIds: action.selectedIds };
    case 'SET_PAGE':
      return { ...state, page: action.page };
    case 'SET_ROWS_PER_PAGE':
      return { ...state, page: action.page, rowsPerPage: action.rowsPerPage };
    default:
      return state;
  }
};

export const TableContext = createContext({} as TableContextData);

export function TableProvider(props: TableProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { selected, selectedIds } = state;

  // const handleSetName = (name: string) => {
  //   dispatch({ type: 'SET_SELECTED', name });
  // };

  // const [order, setOrder] = useState<Order>('asc');
  // const [orderBy, setOrderBy] = useState(head[0]);
  // const [selected, setSelected] = useState<readonly string[]>([]);
  // const [selectedIds, setSelectedIds] = useState<string[]>([]);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [contextMenuOpened, setContextMenuOpened] = useState(false);
  // const onMoreExists = Boolean(onMoreDetails);
  // const onTractReplicateExists = Boolean(onTractReplicate);
  // const onPrintExists = Boolean(onPrint);
  // const onDeleteAllSelectedExists = Boolean(onDeleteAllSelected);

  const handleRowClick = (data: ITableBody, _id: number) => {
    const selectedIndex = selected.indexOf(_id.toString());
    let newSelected: string[] = [];
    let newSelectedIds = selectedIds;

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id.toString());
      newSelectedIds = [...newSelectedIds, data.id];
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedIds = newSelectedIds.filter((id) => id !== data.id);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedIds = newSelectedIds.filter((id) => id !== data.id);
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      newSelectedIds = newSelectedIds.filter((id) => id !== data.id);
    }

    dispatch({ type: 'SET_ROW_CLICK', selected: newSelected, selectedIds: newSelectedIds });
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = props.tableBody.map((_, index) => index.toString());
      const allIds = props.tableBody.map((n) => n.id);

      return dispatch({ type: 'SET_ROW_CLICK', selected: newSelected, selectedIds: allIds });
    }

    return dispatch({ type: 'SET_ROW_CLICK', selected: [], selectedIds: [] });
  };

  const handleChangePage = (page: number) => {
    return dispatch({ type: 'SET_PAGE', page });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    return dispatch({ type: 'SET_ROWS_PER_PAGE', page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleClearSelections = () => {
    dispatch({ type: 'SET_ROW_CLICK', selected: [], selectedIds: [] });
  };

  // const handleRequestSort = (property: string) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  // const handleContextMenu = (_id: string, type: 'open' | 'close') => {
  //   if (type === 'open') {
  //     setSelected([_id]);
  //     setContextMenuOpened(true);
  //     return;
  //   }

  //   setSelected([]);
  //   setContextMenuOpened(false);
  // };

  // function handleDeleteAll() {
  //   if (!onDeleteAllSelected) return;
  //   onDeleteAllSelected(selectedIds);
  // }

  // function handleEditAll() {
  //   if (!onEditAllSelected) return;
  //   onEditAllSelected(selectedIds);
  // }

  // function handleMoreDetails() {
  //   if (!onMoreDetails) return;
  //   onMoreDetails(selectedIds[0]);
  // }

  // function handlePrint() {
  //   if (!onPrint) return;
  //   onPrint(selectedIds[0]);
  // }

  // function handleGenerateAnnouncement() {
  //   if (!onGenerateAnnouncement) return;
  //   onGenerateAnnouncement(selectedIds[0]);
  // }

  // function handleTractReplicate() {
  //   if (!onTractReplicate) return;

  //   onTractReplicate(selectedIds);
  // }

  // function handleNextDay() {
  //   if (!onSelectDay) return;

  //   onSelectDay(selectedIds[0]);
  // }

  return (
    <TableContext.Provider
      value={{
        ...state,

        handleRowClick,
        handleSelectAll,
        handleChangePage,
        handleChangeRowsPerPage,
        handleClearSelections
      }}
    >
      {props.children}
    </TableContext.Provider>
  );
}
