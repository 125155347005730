import React, { memo, useEffect, useRef, useState } from 'react';

import type { TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { TextField as MuiTextField } from '@mui/material';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MuiDatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ptLocale from 'date-fns/locale/pt-BR';

import { useCommonLayout } from '@/contexts/commonLayout';

import { adjustToUserTimezone } from '@/utils/formatDate';
import { useField } from '@unform/core';

type TextFieldProps = MuiTextFieldProps & {
  name: string;
  label: string;
  onChangeValue?(value: any): void;
  defaultValue?: Date;
};

const UnDatePicker: React.FC<TextFieldProps> = ({
  name,
  label,
  helperText,
  defaultValue,
  InputLabelProps,
  onChangeValue,
  ...restProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  const { farmDayUTC } = useCommonLayout();

  const defaultInputValue = defaultValue ? adjustToUserTimezone(defaultValue) : adjustToUserTimezone(farmDayUTC);

  const [value, setValue] = useState<Date>(defaultInputValue);

  async function getDate(date: Date) {
    const aux = new Date(date);
    setValue(aux);
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
  }, [fieldName, registerField, defaultValue, value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale} dateFormats={{ fullDate: 'yy-dd-mm' }}>
      <MuiDatePicker
        className="date-picker-root"
        disabled={restProps.disabled}
        inputRef={inputRef}
        label={error && helperText ? helperText : error ? error : label}
        value={value}
        onChange={(newValue) => {
          if (newValue) getDate(newValue);
          if (onChangeValue) {
            onChangeValue(newValue);
          }
        }}
        renderInput={(params) => (
          <MuiTextField
            className="text-field-root"
            error={true}
            onFocus={() => clearError()}
            style={{
              width: '100%'
            }}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};

/** @deprecated Criar o componente DatePickerRHF */
const DatePicker = memo(UnDatePicker);

export { DatePicker };
