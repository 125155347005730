import { forwardRef, useState } from 'react';
import NumberFormat from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const PhoneMaskCustom = forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      format="(##) #####-####"
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
    />
  );
});

const CpfCnpjMaskCustom = forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  const [isCpf, setIsCpf] = useState('');
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      format={isCpf.length < 12 ? '###.###.###-###' : '##.###.###/####-##'}
      onValueChange={(values) => {
        setIsCpf(values.value);

        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
    />
  );
});

const ZipCodeMaskCustom = forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      format="#####-###"
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
    />
  );
});

const DateMaskCustom = forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      format="##/##/##"
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
    />
  );
});

const WeightAverage = forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      suffix="  Kg/un"
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
    />
  );
});

const WeightAveragePerDay = forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      suffix=" Kg/dia"
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
    />
  );
});

const handleMoney = (numb: any) => {
  const formatedNumber = numb.replace(',', '.').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  return `R$ ${formatedNumber}`;
};

const MoneyMaskCustom = forwardRef<NumberFormat<string>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      // displayType={'text'}
      thousandSeparator={true}
      prefix={'R$ '}
      format={handleMoney}
      maxLength={16}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: handleMoney(values.value)
          }
        });
      }}
    />
  );
});

const PercentageMaskCustom = forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      suffix={' %'}
      onValueChange={(values) => {
        const formatNum = parseFloat(values.value).toFixed(2);
        onChange({
          target: {
            name: props.name,
            value: formatNum
          }
        });
      }}
    />
  );
});

function maskCurrency(valor: any, locale = 'pt-BR', currency = 'BRL') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  }).format(valor);
}

const numberMaskCustom = forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      // suffix={' Kg'}
      onValueChange={(values) => {
        const formatNum = parseFloat(values.value).toFixed(2);
        onChange({
          target: {
            name: props.name,
            value: formatNum
          }
        });
      }}
    />
  );
});

const numberMask = forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      // suffix={' Kg'}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
    />
  );
});

export {
  CpfCnpjMaskCustom,
  DateMaskCustom,
  MoneyMaskCustom,
  PercentageMaskCustom,
  PhoneMaskCustom,
  WeightAverage,
  WeightAveragePerDay,
  ZipCodeMaskCustom,
  maskCurrency,
  numberMask,
  numberMaskCustom
};
