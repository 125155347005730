import { Accordion, AccordionDetails, AccordionSummary } from '@components';
import { useSession } from '@hooks/useSession';
import { memo, useState } from 'react';

type NavigationAccordionProps = {
  children: React.ReactNode;
  panelNames: string[];
  title: string;
};

function navigationAccordion({ children, panelNames, title }: NavigationAccordionProps) {
  const { currentAccordion } = useSession();

  const [expandedPanel, setExpandedPanel] = useState(panelNames.indexOf(currentAccordion) > -1);
  function onHandleExpanded() {
    setExpandedPanel(!expandedPanel);
  }

  return (
    <Accordion expanded={expandedPanel} onChange={onHandleExpanded}>
      <AccordionSummary>{title}</AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

const NavigationAccordion = memo(navigationAccordion);

export { NavigationAccordion };
