import { EnumsReadType } from '@query';
import styled from 'styled-components';

type NightlyReadItemProps = {
  color: 'green' | 'red';
};

type TagProps = {
  color: EnumsReadType;
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
`;

export const Content = styled.div`
  width: 100%;
  padding: 0 16px;
`;

export const BoxContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & > div:nth-child(1),
  & > div:nth-child(2) {
    border-top: ${(props) => props.theme.border.border100};
  }
`;

export const BoxItems = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  border-right: ${(props) => props.theme.border.border100};
  border-bottom: ${(props) => props.theme.border.border100};
`;

export const BoxItemTitle = styled.span`
  font-size: 1.2rem;
  font-weight: 800;
  color: ${(props) => props.theme.colors.text90};
`;

export const BoxItemText = styled.span`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${(props) => props.theme.colors.text100};
`;

export const NightlyReadContainer = styled.div`
  width: 100%;
  min-height: 25px;
  display: flex;
  justify-content: center;
  gap: 4px;
`;

export const NightlyReadItem = styled.div<NightlyReadItemProps>`
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  background: ${({ color, theme }) => (color === 'green' ? theme.colors.green : theme.colors.red)};
  color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.text100};
`;

export const TagItem = styled.div<TagProps>`
  width: fit-content;
  padding: 8px 16px;
  border-radius: 49px;
  background: ${({ color, theme }) =>
    color === EnumsReadType.DRY
      ? theme.colors.orange
      : color === EnumsReadType.INVENTORY
      ? theme.colors.red
      : theme.colors.green};
  font-size: 18px;
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  font-weight: 500;
`;
