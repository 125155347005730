import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
    font-family: 'Roboto', Sans-Serif;
  }

  body{
    background-color: #fff;
  }

  a{
    text-decoration: none;
  }

  form {
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background: #c6c7c8;
  }

  ::-webkit-scrollbar-track {
    -webkit-border-radius: 0px;
    border-radius: 0px;
  }

  .default-btn,
  .btn-primary {
    cursor: pointer;
    padding: 12px 16px;
    background-color: transparent;
    color: ${(props) => props.theme.colors.text90};
    border: ${(props) => props.theme.border.defaultBorder};
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    outline: none;
    transition: 300ms linear;
    margin-left: 16px;
  }

  .btn-primary {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
  }

  .header-btn{
    font-size: 12px;
    font-weight: 700;
    height: 32px;
    padding: 0px 12px;
    border: none;
    background-color: ${(props) => props.theme.colors.hover};
  }

  .header-table-btn{
    width: max-content;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    padding: 0px 12px;
    border-radius: 4px;
    border: none;
    color: ${(props) => props.theme.colors.text90};
    background-color: ${(props) => props.theme.colors.hover};
    transition: 300ms linear;
    cursor: pointer;
    
  }

  .header-table-btn-filter{
    width: max-content;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    padding: 0px 12px;
    border-radius: 4px;
    border: none;
    color: ${(props) => props.theme.colors.text90};
    background-color: ${(props) => props.theme.colors.hover};
    transition: 300ms linear;
    cursor: pointer;
    margin-right: 12px;
  }

  .box{
    background-color: ${(props) => props.theme.colors.bg90};
    color: ${(props) => props.theme.colors.text100};
    box-shadow: ${(props) => props.theme.boxShadow.shadow80};  
    border-radius: 8px;
    margin-bottom: 16px;
    border-top: 5px solid ${(props) => props.theme.colors.primary};

    .header{
      height: 54px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      border-bottom: ${(props) => props.theme.border.border100};
      font-weight: 500;
      color: ${(props) => props.theme.colors.text100};
    }

    .body{
      padding: 16px;
      width: 100%;
    }

    .line-grid{
      display: grid;

      grid-template-areas: 
        '. .'
        '. .'
        '. .';

      grid-template-columns: auto;
      grid-template-rows: auto;
      gap: 16px;
    }

    .footer{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px;
      border-top: ${(props) => props.theme.border.border100};
      font-weight: 500;
      color: ${(props) => props.theme.colors.text100};
    }

  }

  .g-map{
    padding: 0 !important; 
    width: 100%;
    height: 500px;

    /* > div:nth-of-type(1), 
    > div:nth-of-type(2) { 
      border-radius: 0 0 8px 8px;
    } */

    .gm-bundled-control, 

    .gm-bundled-control-on-bottom{
      display: block !important;

      .gmnoprint{
        display: block !important;
      }

    }

    .gmnoprint,
    .gm-style-cc{
      display: none;
    }

    .infoBox {
      overflow: inherit !important;

      > div:nth-of-type(1) { 
        margin-top: 8px;
        margin-left: -12px;
        background-color: ${(props) => props.theme.colors.bg90};
        border-radius: 4px;
      }

      div div{
        width: max-content;
        padding: 8px 12px;
        border-radius: 4px;
        background: #fff;
        margin: 0;
        position: relative;

        :before{
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          background-color: #fff;
          position: absolute;
          left: 8px;
          top: -4px;
          transform: rotate(45deg);
          z-index: 1;
        }

      }

    }

  }

  .suite-panel{
    ul {
      padding: 0 !important;
    }
  }

  .MuiOutlinedInput-input.Mui-disabled{
    cursor: no-drop !important;
  }

  

  
  .full-table-root{
    .MuiPaper-root{
      background-color: ${(props) => props.theme.colors.bg90} !important;
    }

    .toolbar-root{
      color: ${(props) => props.theme.colors.text100} !important;

      .MuiButtonBase-root{
        color: ${(props) => props.theme.colors.text90} !important;
      }
    }

    .MuiTableContainer-root{
      .MuiTableHead-root{
        .MuiCheckbox-root {
          color: ${(props) => props.theme.colors.text90} !important;
        }

        th {
          color: ${(props) => props.theme.colors.text90} !important;
          border-bottom-color: ${(props) => props.theme.tags.bgDefault} !important;
        }
      }

      .MuiTableBody-root{
        .MuiCheckbox-root {
          color: ${(props) => props.theme.colors.text100} !important;
        }


        td{
          color: ${(props) => props.theme.colors.text100} !important;
          border-bottom-color: ${(props) => props.theme.tags.bgDefault} !important;
        }
      }

      .MuiCheckbox-root.Mui-checked {
        color: ${(props) => props.theme.colors.primary} !important;
      }
      
      tr.Mui-selected{
        background-color: ${(props) => props.theme.colors.hover} !important;
      }
    }

    .MuiTablePagination-root{
      color: ${(props) => props.theme.colors.text90} !important;

      svg {
        color: ${(props) => props.theme.colors.text90} !important;
      }

      .MuiPaper-root {
        background-color: ${(props) => props.theme.colors.bg80} !important;
      }
    }
  }

  /* .MuiList-root{
    background-color: ${(props) => props.theme.colors.bg80} !important;
    color: ${(props) => props.theme.colors.text90} !important;

    .Mui-selected{
      background-color: ${(props) => props.theme.colors.primary} !important;
      color: ${(props) => props.theme.colors.white} !important;
    }
  } */

  .simple-modal-root{
    .MuiPaper-root{
      background-color: ${(props) => props.theme.colors.bg80} !important;
    }
  }

  .checkbox-group-root{
    .title {
      color: ${(props) => props.theme.colors.text90} !important;
    }

    .MuiFormControlLabel-root{
      color: ${(props) => props.theme.colors.text100} !important;

      .MuiCheckbox-root{
        color: ${(props) => props.theme.colors.text100} !important;
      }

      .MuiCheckbox-root.Mui-checked {
        color: ${(props) => props.theme.colors.primary} !important;
      }
    }
  }

  /* .MuiSwitch-root {
    .MuiSwitch-track{
      background-color: ${(props) => props.theme.colors.text90} !important;
    }

    .MuiSwitch-switchBase.Mui-checked {
      color: ${(props) => props.theme.colors.primary} !important;
    }
  } */

  .MuiSkeleton-root{
    background-color: ${(props) => props.theme.mui.skeleton} !important;

    ::after {
      background: ${(props) => props.theme.mui.skeletonAfter} !important;
    }
  }

  .drag-handle{
    position: absolute;
    right: 0;
    color: rgba(0,0,0,0.5);
    z-index: 99;
  }
  
  /* .drag-handle{
    background-color: red;
    width: 24px;
    height: 24px;
  } */
`;

export default GlobalStyle;
