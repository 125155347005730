import { AxiosInstance } from '@/api';
import { AUTH_KEY, FARM_DAY, FARM_DAY_KEY, FEED_SESSION } from '@/constants';
import { LoginResponse, LoginResponseUser } from '@/query';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { createContext as createContextSelector, useContextSelector } from 'use-context-selector';

export enum Sessions {
  admin = 'Painel',
  reports = 'Relatórios',
  management = 'Manejo',
  tract = 'Trato',
  diet = 'Dieta',
  financial = 'Financeiro',
  traceability = 'Rastreabilidade',
  farm = 'Fazenda',
  medicalServices = 'Sanidade',
  logout = 'Sair',
  batches = 'Lotes avulsos',
  forecast = 'Forecast'
}

type ContextData = {
  user: LoginResponseUser;
  token: string;
  refresh: string;
  farmDay: Date;
  farmDayUTC: string;
  openMenu: boolean;
  currentSession: Sessions | null;
  currentNavigation: string;

  handleOpenMenu: () => void;
  handleCurrentSession: (session: Sessions) => void;
  handleCurrentNavigation: (navigation: string) => void;
  handleFarmDay: (date: Date) => void;
};

type ProviderProps = {
  children: ReactNode;
};

export const CommonLayoutContext = createContextSelector({} as ContextData);

export function CommonLayoutProvider(props: ProviderProps) {
  const navigate = useNavigate();

  const openMenuLocal = localStorage.getItem('FEED:open-menu');
  const currentNavigationLocal = localStorage.getItem('FEED:current-navigation');

  const auth: LoginResponse = JSON.parse(localStorage.getItem(AUTH_KEY) as any);

  const user: LoginResponseUser = auth.user;
  const token = auth.token;
  const refresh = auth.refresh;

  const farmDayUTC = localStorage.getItem(FARM_DAY_KEY) as string;
  const day = new Date(farmDayUTC);
  const [farmDay, setFarmDay] = useState<Date>(day);

  const [openMenu, setOpenMenu] = useState<boolean>(openMenuLocal ? JSON.parse(openMenuLocal) : false);
  const [currentSession, setCurrentSession] = useState<Sessions | null>(null);
  const currentNavigation = currentNavigationLocal ? JSON.parse(currentNavigationLocal) : '';

  const handleOpenMenu = useCallback(() => {
    setOpenMenu((prevState) => {
      localStorage.setItem('FEED:open-menu', JSON.stringify(!prevState));

      return !prevState;
    });
  }, []);

  const handleCurrentSession = (session: Sessions) => {
    setCurrentSession(session);
    localStorage.setItem(FEED_SESSION, session);
  };

  const handleCurrentNavigation = useCallback((navigation: string) => {
    if (navigation === '') return;

    localStorage.setItem('FEED:current-navigation', JSON.stringify(navigation));

    navigate(navigation);
  }, []);

  const handleFarmDay = useCallback(
    (date: Date) => {
      localStorage.setItem(FARM_DAY_KEY, date.toISOString());

      AxiosInstance.defaults.headers.common[FARM_DAY] = date.toISOString();

      setFarmDay(date);
    },
    [farmDay]
  );

  function getSession(pathUrl: string): Sessions | null {
    if (pathUrl === '/admin') return Sessions.admin;
    else if (pathUrl.includes('/admin/reports')) return Sessions.reports;
    else if (pathUrl.includes('/admin/forecast')) return Sessions.forecast;
    else if (pathUrl.includes('/admin/management')) return Sessions.management;
    else if (pathUrl.includes('/admin/tract')) return Sessions.tract;
    else if (pathUrl.includes('/admin/diet')) return Sessions.diet;
    else if (pathUrl.includes('/admin/financial')) return Sessions.financial;
    else if (pathUrl.includes('/admin/batches')) return Sessions.batches;
    else if (pathUrl.includes('/admin/farm')) return Sessions.farm;
    else if (pathUrl.includes('/admin/traceability')) return Sessions.traceability;
    else if (pathUrl.includes('/admin/medical-services')) return Sessions.medicalServices;
    else return null;
  }

  useEffect(() => {
    const pathUrl = window.location.pathname;
    const session = getSession(pathUrl);

    if (session !== null) {
      handleCurrentSession(session);
    }
  }, []);

  return (
    <CommonLayoutContext.Provider
      value={{
        user,
        token,
        refresh,
        farmDay,
        farmDayUTC,
        openMenu,
        currentSession,
        currentNavigation,

        handleOpenMenu,
        handleCurrentSession,
        handleCurrentNavigation,
        handleFarmDay
      }}
    >
      {props.children}
    </CommonLayoutContext.Provider>
  );
}

export function useCommonLayout() {
  const user = useContextSelector(CommonLayoutContext, (context) => context.user);
  const token = useContextSelector(CommonLayoutContext, (context) => context.token);
  const refresh = useContextSelector(CommonLayoutContext, (context) => context.refresh);
  const farmDay = useContextSelector(CommonLayoutContext, (context) => context.farmDay);
  const farmDayUTC = useContextSelector(CommonLayoutContext, (context) => context.farmDayUTC);
  const openMenu = useContextSelector(CommonLayoutContext, (context) => context.openMenu);
  const currentSession = useContextSelector(CommonLayoutContext, (context) => context.currentSession);
  const currentNavigation = useContextSelector(CommonLayoutContext, (context) => context.currentNavigation);

  const handleOpenMenu = useContextSelector(CommonLayoutContext, (context) => context.handleOpenMenu);
  const handleCurrentSession = useContextSelector(CommonLayoutContext, (context) => context.handleCurrentSession);
  const handleCurrentNavigation = useContextSelector(CommonLayoutContext, (context) => context.handleCurrentNavigation);
  const handleFarmDay = useContextSelector(CommonLayoutContext, (context) => context.handleFarmDay);

  return {
    user,
    token,
    refresh,
    farmDay,
    farmDayUTC,
    openMenu,
    currentSession,
    Sessions,
    currentNavigation,

    handleOpenMenu,
    handleCurrentSession,
    handleCurrentNavigation,
    handleFarmDay
  };
}
