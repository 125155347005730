import type { TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { Autocomplete, TextField as MuiTextField } from '@mui/material';
import { useField } from '@unform/core';
import { memo, useEffect, useRef, useState } from 'react';

/** @deprecated Usar o componente AutoCompleteRHF */
export type OptionsProps = {
  label: string;
  value: string;
};

type TextFieldProps = {
  name: string;
  label: string;
  options: OptionsProps[] | [];
  defaultValue?: OptionsProps | OptionsProps[] | [];
  refetchDefaultValue?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  freeSolo?: boolean;
  loading?: boolean;
  autoFocus?: boolean;
  shrink?: boolean;
  disableClearable?: boolean;

  onChange?: (event: any) => void;
  groupBy?(option: any): string;
  getIndex?(index: any): void;
  getValue?(value: OptionsProps): void;
  onOpen?(value: boolean): void;
  handleScroll?: (event: any) => void;

  renderOption?: (props: any, option: any) => JSX.Element;
};

function MuiAutoComplete(props: TextFieldProps) {
  return <RenderAutoComplete {...props} />;
}

function RenderAutoComplete({
  name,
  label,
  options,
  defaultValue,
  disabled,
  multiple = false,
  size = 'medium',
  freeSolo = false,
  loading,
  autoFocus,
  refetchDefaultValue,

  onChange,
  groupBy,
  getIndex,
  getValue,
  onOpen,
  handleScroll,
  disableClearable,

  // MuiTextFieldProps props
  helperText,
  InputLabelProps,
  shrink,
  renderOption,
  ...restProps
}: TextFieldProps & MuiTextFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [autoCompleteKey, setAutoCompleteKey] = useState(0);
  // @ts-expect-error
  const [inputPath, setInputPath] = useState<OptionsProps | OptionsProps[]>(defaultValue);

  const { fieldName, registerField, error, clearError } = useField(name);

  function renderDefaultValue(value: OptionsProps | OptionsProps[]) {
    if (Array.isArray(value)) {
      const sanitizeValue = value.filter((item) => item.value && item.label);

      if (multiple) return sanitizeValue;

      return sanitizeValue.length >= 0 ? sanitizeValue[0] : {};
    }

    if (value.value && value.label) return value;

    return {};
  }

  function handleChange(event: any, value: OptionsProps | OptionsProps[]) {
    setInputPath(value);

    if (onChange) onChange(event);
    if (getIndex) getIndex(event.target.attributes[3].value);
    if (getValue) getValue(value as OptionsProps);
  }

  function handleOpen(value: boolean) {
    if (onOpen) onOpen(value);
  }

  function handleChangeInput(value: string) {
    if (!freeSolo) return;

    setInputPath({
      label: value,
      value
    });
  }

  useEffect(() => {
    if (options.length === 0) return;

    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue() {
        return inputPath;
      }
    });
  }, [options]);

  useEffect(() => {
    if (!inputPath) return;

    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue() {
        return inputPath;
      }
    });
  }, [inputPath]);

  useEffect(() => {
    if (!defaultValue) return;

    if (refetchDefaultValue) {
      setAutoCompleteKey((prevKey) => prevKey + 1);
    }

    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue() {
        return defaultValue;
      },
      setValue: (ref, newValue) => {
        ref.current.value = newValue;
      }
    });
  }, [defaultValue]);

  return (
    <Autocomplete
      key={autoCompleteKey}
      className="autocomplete-root"
      loading={loading}
      onOpen={() => handleOpen(true)}
      options={options}
      defaultValue={defaultValue && renderDefaultValue(defaultValue)}
      // value={useValue && defaultValue}
      multiple={multiple}
      disabled={disabled}
      disableClearable={disableClearable}
      // @ts-expect-error
      onChange={(event, value) => handleChange(event, value)}
      groupBy={groupBy}
      // @ts-expect-error
      isOptionEqualToValue={(option, value) => option.value === value.value}
      // @ts-expect-error
      getOptionLabel={(option) => option.label || ''}
      noOptionsText="Nenhuma opção encontrada"
      size={size}
      freeSolo={freeSolo}
      autoComplete={false}
      renderOption={renderOption}
      renderInput={(params) => (
        <MuiTextField
          className="text-field-root"
          inputRef={inputRef}
          label={error && helperText ? helperText : error ? error : label}
          error={!!error}
          // helperText={error || helperText}
          onFocus={() => clearError()}
          onChange={(e) => handleChangeInput(e.target.value)}
          autoComplete="off"
          autoFocus={autoFocus}
          {...params}
          {...restProps}
        />
      )}
      ListboxProps={{
        onScroll: handleScroll
      }}
      sx={{
        width: '100%'
      }}
    />
  );
}

/** @deprecated Criar o componente AutoCompleteRHF */
const AutoComplete = memo(MuiAutoComplete);

export { AutoComplete };
