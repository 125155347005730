// @ts-nocheck
// eslint-disable 
// prettier-ignore

// Generated by Vite Router
// https://www.npmjs.com/package/vite-plugin-router
// https://github.com/felipe-bergamaschi/vite-router

import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import type { RouteProps } from 'vite-plugin-router';

const Route0 = lazy(() => import('./app/admin/batches/add/index'));
const Route1 = lazy(() => import('./app/admin/batches/closure/index'));
const Route2 = lazy(() => import('./app/admin/batches/index'));
const Route3 = lazy(() => import('./app/admin/diet/add/index'));
const Route4 = lazy(() => import('./app/admin/diet/edit/[id]'));
const Route5 = lazy(() => import('./app/admin/diet/list/index'));
const Route6 = lazy(() => import('./app/admin/diet/pre-mix/add/index'));
const Route7 = lazy(() => import('./app/admin/diet/pre-mix/composition/index'));
const Route8 = lazy(() => import('./app/admin/diet/pre-mix/edit/[id]'));
const Route9 = lazy(() => import('./app/admin/diet/pre-mix/list/index'));
const Route10 = lazy(() => import('./app/admin/diet/premix/add/index'));
const Route11 = lazy(() => import('./app/admin/diet/premix/edit/[id]'));
const Route12 = lazy(() => import('./app/admin/diet/premix/list/index'));
const Route13 = lazy(() => import('./app/admin/diet/supplement/add/index'));
const Route14 = lazy(() => import('./app/admin/diet/supplement/edit/[id]'));
const Route15 = lazy(() => import('./app/admin/diet/supplement/list/index'));
const Route16 = lazy(() => import('./app/admin/farm/backup/download'));
const Route17 = lazy(() => import('./app/admin/farm/backup/upload'));
const Route18 = lazy(() => import('./app/admin/farm/batches/add/index'));
const Route19 = lazy(() => import('./app/admin/farm/batches/edit/[id]'));
const Route20 = lazy(() => import('./app/admin/farm/batches/list/index'));
const Route21 = lazy(() => import('./app/admin/farm/data/address/index'));
const Route22 = lazy(() => import('./app/admin/farm/data/factory/index'));
const Route23 = lazy(() => import('./app/admin/farm/data/index'));
const Route24 = lazy(() => import('./app/admin/farm/data/structures/index'));
const Route25 = lazy(() => import('./app/admin/farm/days/index'));
const Route26 = lazy(() => import('./app/admin/farm/index'));
const Route27 = lazy(() => import('./app/admin/farm/mixers/index'));
const Route28 = lazy(() => import('./app/admin/farm/owners/add/index'));
const Route29 = lazy(() => import('./app/admin/farm/owners/edit/[id]'));
const Route30 = lazy(() => import('./app/admin/farm/owners/list/index'));
const Route31 = lazy(() => import('./app/admin/farm/pastures/add/index'));
const Route32 = lazy(() => import('./app/admin/farm/pastures/edit/[id]'));
const Route33 = lazy(() => import('./app/admin/farm/pastures/list/index'));
const Route34 = lazy(() => import('./app/admin/farm/pickets/add/index'));
const Route35 = lazy(() => import('./app/admin/farm/pickets/edit/[id]'));
const Route36 = lazy(() => import('./app/admin/farm/pickets/list/index'));
const Route37 = lazy(() => import('./app/admin/farm/recipes/index'));
const Route38 = lazy(() => import('./app/admin/farm/settings/financial-reports/index'));
const Route39 = lazy(() => import('./app/admin/farm/settings/grades/index'));
const Route40 = lazy(() => import('./app/admin/farm/settings/night-reading/index'));
const Route41 = lazy(() => import('./app/admin/financial/animal/add/index'));
const Route42 = lazy(() => import('./app/admin/financial/animal/edit/[id]'));
const Route43 = lazy(() => import('./app/admin/financial/animal/list/index'));
const Route44 = lazy(() => import('./app/admin/financial/earring/add/index'));
const Route45 = lazy(() => import('./app/admin/financial/earring/edit/[id]'));
const Route46 = lazy(() => import('./app/admin/financial/earring/list/index'));
const Route47 = lazy(() => import('./app/admin/financial/farm-foods-create/index'));
const Route48 = lazy(() => import('./app/admin/financial/food/add/index'));
const Route49 = lazy(() => import('./app/admin/financial/food/edit/[id]'));
const Route50 = lazy(() => import('./app/admin/financial/food/list/index'));
const Route51 = lazy(() => import('./app/admin/financial/gta-create/index'));
const Route52 = lazy(() => import('./app/admin/financial/gta-details/[id]'));
const Route53 = lazy(() => import('./app/admin/financial/gta-list/index'));
const Route54 = lazy(() => import('./app/admin/financial/index'));
const Route55 = lazy(() => import('./app/admin/financial/new-order/index'));
const Route56 = lazy(() => import('./app/admin/financial/origins/add/index'));
const Route57 = lazy(() => import('./app/admin/financial/origins/edit/[id]'));
const Route58 = lazy(() => import('./app/admin/financial/origins/list/index'));
const Route59 = lazy(() => import('./app/admin/financial/stock-add/index'));
const Route60 = lazy(() => import('./app/admin/financial/stock-list/index'));
const Route61 = lazy(() => import('./app/admin/financial/styles'));
const Route62 = lazy(() => import('./app/admin/forecast/index'));
const Route63 = lazy(() => import('./app/admin/index'));
const Route65 = lazy(() => import('./app/admin/management/batch-active/[id]'));
const Route66 = lazy(() => import('./app/admin/management/batch-active/index'));
const Route67 = lazy(() => import('./app/admin/management/batch-closing-edit/[id]'));
const Route68 = lazy(() => import('./app/admin/management/batch-closing-list/index'));
const Route69 = lazy(() => import('./app/admin/management/batch-edit/[id]'));
const Route70 = lazy(() => import('./app/admin/management/batches/index'));
const Route71 = lazy(() => import('./app/admin/management/closed-list/index'));
const Route72 = lazy(() => import('./app/admin/management/closures/list/details/[id]'));
const Route73 = lazy(() => import('./app/admin/management/closures/list/index'));
const Route74 = lazy(() => import('./app/admin/management/closures/new/index'));
const Route75 = lazy(() => import('./app/admin/management/closures/trucks/details/[id]'));
const Route76 = lazy(() => import('./app/admin/management/closures/trucks/index'));
const Route77 = lazy(() => import('./app/admin/management/execute/entry/[id]'));
const Route78 = lazy(() => import('./app/admin/management/execute/identification/[id]'));
const Route79 = lazy(() => import('./app/admin/management/execute/index'));
const Route80 = lazy(() => import('./app/admin/management/execute/sale/[id]'));
const Route81 = lazy(() => import('./app/admin/management/execute/weighing/[id]'));
const Route82 = lazy(() => import('./app/admin/management/index'));
const Route83 = lazy(() => import('./app/admin/management/new/index'));
const Route84 = lazy(() => import('./app/admin/medical-services/index'));
const Route85 = lazy(() => import('./app/admin/medical-services/medicines/add/index'));
const Route86 = lazy(() => import('./app/admin/medical-services/medicines/apply/index'));
const Route87 = lazy(() => import('./app/admin/medical-services/medicines/edit/[id]'));
const Route88 = lazy(() => import('./app/admin/medical-services/medicines/list/index'));
const Route89 = lazy(() => import('./app/admin/medical-services/mortality/add/index'));
const Route90 = lazy(() => import('./app/admin/medical-services/mortality/edit/[id]'));
const Route91 = lazy(() => import('./app/admin/medical-services/mortality/list/index'));
const Route92 = lazy(() => import('./app/admin/medical-services/protocols/add/index'));
const Route93 = lazy(() => import('./app/admin/medical-services/protocols/details/[id]'));
const Route94 = lazy(() => import('./app/admin/medical-services/protocols/list/index'));
const Route95 = lazy(() => import('./app/admin/reports/analytical-report/index'));
const Route96 = lazy(() => import('./app/admin/reports/batches-history/index'));
const Route97 = lazy(() => import('./app/admin/reports/batches-reports/index'));
const Route98 = lazy(() => import('./app/admin/reports/diet-preparation-efficiency/index'));
const Route99 = lazy(() => import('./app/admin/reports/economic-batches-reports/index'));
const Route100 = lazy(() => import('./app/admin/reports/index'));
const Route101 = lazy(() => import('./app/admin/reports/individual-cattle-closing/index'));
const Route102 = lazy(() => import('./app/admin/reports/pastures-reports/index'));
const Route103 = lazy(() => import('./app/admin/reports/stock-farm-history/index'));
const Route104 = lazy(() => import('./app/admin/reports/stock-history/index'));
const Route105 = lazy(() => import('./app/admin/reports/stock-reports/index'));
const Route106 = lazy(() => import('./app/admin/reports/styles'));
const Route107 = lazy(() => import('./app/admin/reports/tract-efficiency-reports/index'));
const Route108 = lazy(() => import('./app/admin/reports/zootechnical-indices/index'));
const Route109 = lazy(() => import('./app/admin/traceability/bnd/import/index'));
const Route110 = lazy(() => import('./app/admin/traceability/bnd/list/index'));
const Route111 = lazy(() => import('./app/admin/traceability/certifier/import/index'));
const Route112 = lazy(() => import('./app/admin/traceability/communique/change-characteristic/edit/[id]'));
const Route113 = lazy(() => import('./app/admin/traceability/communique/change-characteristic/index'));
const Route114 = lazy(() => import('./app/admin/traceability/communique/death/edit/[id]'));
const Route115 = lazy(() => import('./app/admin/traceability/communique/death/index'));
const Route116 = lazy(() => import('./app/admin/traceability/communique/entry/emit/[id]'));
const Route117 = lazy(() => import('./app/admin/traceability/communique/entry/index'));
const Route118 = lazy(() => import('./app/admin/traceability/communique/exit/index'));
const Route119 = lazy(() => import('./app/admin/traceability/communique/overview/index'));
const Route120 = lazy(() => import('./app/admin/traceability/communique/re-identification/edit/[id]'));
const Route121 = lazy(() => import('./app/admin/traceability/communique/re-identification/index'));
const Route122 = lazy(() => import('./app/admin/traceability/communique/unusability/edit/[id]'));
const Route123 = lazy(() => import('./app/admin/traceability/communique/unusability/index'));
const Route124 = lazy(() => import('./app/admin/traceability/earring/details/[id]'));
const Route125 = lazy(() => import('./app/admin/traceability/earring/list/index'));
const Route126 = lazy(() => import('./app/admin/traceability/index'));
const Route127 = lazy(() => import('./app/admin/traceability/ox-search/index'));
const Route128 = lazy(() => import('./app/admin/traceability/spreadsheets/birth/index'));
const Route129 = lazy(() => import('./app/admin/traceability/spreadsheets/entry/index'));
const Route130 = lazy(() => import('./app/admin/traceability/spreadsheets/identification/index'));
const Route131 = lazy(() => import('./app/admin/tract/adjust-dry-matter/index'));
const Route132 = lazy(() => import('./app/admin/tract/beat-add/index'));
const Route133 = lazy(() => import('./app/admin/tract/beat-history/index'));
const Route134 = lazy(() => import('./app/admin/tract/beat-of-day/index'));
const Route135 = lazy(() => import('./app/admin/tract/change-corral-diet/index'));
const Route136 = lazy(() => import('./app/admin/tract/change-diet/index'));
const Route137 = lazy(() => import('./app/admin/tract/diet-transition-add/index'));
const Route138 = lazy(() => import('./app/admin/tract/diet-transition-add/styles'));
const Route139 = lazy(() => import('./app/admin/tract/diet-transition-list/index'));
const Route140 = lazy(() => import('./app/admin/tract/impression-sheets/index'));
const Route141 = lazy(() => import('./app/admin/tract/index'));
const Route142 = lazy(() => import('./app/admin/tract/management-trough/v2'));
const Route143 = lazy(() => import('./app/admin/tract/occurrences-create/index'));
const Route144 = lazy(() => import('./app/admin/tract/occurrences-create/styles'));
const Route145 = lazy(() => import('./app/admin/tract/occurrences-list/index'));
const Route146 = lazy(() => import('./app/admin/tract/pasture-supplement/index'));
const Route147 = lazy(() => import('./app/admin/tract/pasture-supplement/styles'));
const Route148 = lazy(() => import('./app/admin/tract/planning-add/index'));
const Route149 = lazy(() => import('./app/admin/tract/planning-edit/[id]'));
const Route150 = lazy(() => import('./app/admin/tract/planning-list/index'));
const Route151 = lazy(() => import('./app/admin/tract/replace-planning/index'));
const Route152 = lazy(() => import('./app/admin/tract/replicate/index'));
const Route153 = lazy(() => import('./app/admin/tract/tract-of-day/index'));
const Route154 = lazy(() => import('./app/admin/tract/tract-of-day/styles'));
const Route155 = lazy(() => import('./app/admin/tract/tract-of-day/v2'));
const Route156 = lazy(() => import('./app/create-account/index'));
const Route157 = lazy(() => import('./app/index'));
const Route158 = lazy(() => import('./app/login/index'));
const Route159 = lazy(() => import('./app/login/styles'));
const Route160 = lazy(() => import('./app/privacy'));
const Layout64 = lazy(() => import('./app/admin/layout'));

/**
 * Generated by Vite Router
 *
 * @link https://www.npmjs.com/package/vite-plugin-router
 * @link https://github.com/felipe-bergamaschi/vite-router
 */
export function AppRoutes(props: RouteProps) {
  return (
    <BrowserRouter>
      <Suspense fallback={props.loadingPage || <div>Loading...</div>}>
        <Routes>
          <Route path={"/admin/batches/add"} key={"/admin/batches/add"} element={<Layout64><Route0></Route0></Layout64>}></Route>
<Route path={"/admin/batches/closure"} key={"/admin/batches/closure"} element={<Layout64><Route1></Route1></Layout64>}></Route>
<Route path={"/admin/batches"} key={"/admin/batches"} element={<Layout64><Route2></Route2></Layout64>}></Route>
<Route path={"/admin/diet/add"} key={"/admin/diet/add"} element={<Layout64><Route3></Route3></Layout64>}></Route>
<Route path={"/admin/diet/edit/:id"} key={"/admin/diet/edit/:id"} element={<Layout64><Route4></Route4></Layout64>}></Route>
<Route path={"/admin/diet/list"} key={"/admin/diet/list"} element={<Layout64><Route5></Route5></Layout64>}></Route>
<Route path={"/admin/diet/pre-mix/add"} key={"/admin/diet/pre-mix/add"} element={<Layout64><Route6></Route6></Layout64>}></Route>
<Route path={"/admin/diet/pre-mix/composition"} key={"/admin/diet/pre-mix/composition"} element={<Layout64><Route7></Route7></Layout64>}></Route>
<Route path={"/admin/diet/pre-mix/edit/:id"} key={"/admin/diet/pre-mix/edit/:id"} element={<Layout64><Route8></Route8></Layout64>}></Route>
<Route path={"/admin/diet/pre-mix/list"} key={"/admin/diet/pre-mix/list"} element={<Layout64><Route9></Route9></Layout64>}></Route>
<Route path={"/admin/diet/premix/add"} key={"/admin/diet/premix/add"} element={<Layout64><Route10></Route10></Layout64>}></Route>
<Route path={"/admin/diet/premix/edit/:id"} key={"/admin/diet/premix/edit/:id"} element={<Layout64><Route11></Route11></Layout64>}></Route>
<Route path={"/admin/diet/premix/list"} key={"/admin/diet/premix/list"} element={<Layout64><Route12></Route12></Layout64>}></Route>
<Route path={"/admin/diet/supplement/add"} key={"/admin/diet/supplement/add"} element={<Layout64><Route13></Route13></Layout64>}></Route>
<Route path={"/admin/diet/supplement/edit/:id"} key={"/admin/diet/supplement/edit/:id"} element={<Layout64><Route14></Route14></Layout64>}></Route>
<Route path={"/admin/diet/supplement/list"} key={"/admin/diet/supplement/list"} element={<Layout64><Route15></Route15></Layout64>}></Route>
<Route path={"/admin/farm/backup/download"} key={"/admin/farm/backup/download"} element={<Layout64><Route16></Route16></Layout64>}></Route>
<Route path={"/admin/farm/backup/upload"} key={"/admin/farm/backup/upload"} element={<Layout64><Route17></Route17></Layout64>}></Route>
<Route path={"/admin/farm/batches/add"} key={"/admin/farm/batches/add"} element={<Layout64><Route18></Route18></Layout64>}></Route>
<Route path={"/admin/farm/batches/edit/:id"} key={"/admin/farm/batches/edit/:id"} element={<Layout64><Route19></Route19></Layout64>}></Route>
<Route path={"/admin/farm/batches/list"} key={"/admin/farm/batches/list"} element={<Layout64><Route20></Route20></Layout64>}></Route>
<Route path={"/admin/farm/data/address"} key={"/admin/farm/data/address"} element={<Layout64><Route21></Route21></Layout64>}></Route>
<Route path={"/admin/farm/data/factory"} key={"/admin/farm/data/factory"} element={<Layout64><Route22></Route22></Layout64>}></Route>
<Route path={"/admin/farm/data"} key={"/admin/farm/data"} element={<Layout64><Route23></Route23></Layout64>}></Route>
<Route path={"/admin/farm/data/structures"} key={"/admin/farm/data/structures"} element={<Layout64><Route24></Route24></Layout64>}></Route>
<Route path={"/admin/farm/days"} key={"/admin/farm/days"} element={<Layout64><Route25></Route25></Layout64>}></Route>
<Route path={"/admin/farm"} key={"/admin/farm"} element={<Layout64><Route26></Route26></Layout64>}></Route>
<Route path={"/admin/farm/mixers"} key={"/admin/farm/mixers"} element={<Layout64><Route27></Route27></Layout64>}></Route>
<Route path={"/admin/farm/owners/add"} key={"/admin/farm/owners/add"} element={<Layout64><Route28></Route28></Layout64>}></Route>
<Route path={"/admin/farm/owners/edit/:id"} key={"/admin/farm/owners/edit/:id"} element={<Layout64><Route29></Route29></Layout64>}></Route>
<Route path={"/admin/farm/owners/list"} key={"/admin/farm/owners/list"} element={<Layout64><Route30></Route30></Layout64>}></Route>
<Route path={"/admin/farm/pastures/add"} key={"/admin/farm/pastures/add"} element={<Layout64><Route31></Route31></Layout64>}></Route>
<Route path={"/admin/farm/pastures/edit/:id"} key={"/admin/farm/pastures/edit/:id"} element={<Layout64><Route32></Route32></Layout64>}></Route>
<Route path={"/admin/farm/pastures/list"} key={"/admin/farm/pastures/list"} element={<Layout64><Route33></Route33></Layout64>}></Route>
<Route path={"/admin/farm/pickets/add"} key={"/admin/farm/pickets/add"} element={<Layout64><Route34></Route34></Layout64>}></Route>
<Route path={"/admin/farm/pickets/edit/:id"} key={"/admin/farm/pickets/edit/:id"} element={<Layout64><Route35></Route35></Layout64>}></Route>
<Route path={"/admin/farm/pickets/list"} key={"/admin/farm/pickets/list"} element={<Layout64><Route36></Route36></Layout64>}></Route>
<Route path={"/admin/farm/recipes"} key={"/admin/farm/recipes"} element={<Layout64><Route37></Route37></Layout64>}></Route>
<Route path={"/admin/farm/settings/financial-reports"} key={"/admin/farm/settings/financial-reports"} element={<Layout64><Route38></Route38></Layout64>}></Route>
<Route path={"/admin/farm/settings/grades"} key={"/admin/farm/settings/grades"} element={<Layout64><Route39></Route39></Layout64>}></Route>
<Route path={"/admin/farm/settings/night-reading"} key={"/admin/farm/settings/night-reading"} element={<Layout64><Route40></Route40></Layout64>}></Route>
<Route path={"/admin/financial/animal/add"} key={"/admin/financial/animal/add"} element={<Layout64><Route41></Route41></Layout64>}></Route>
<Route path={"/admin/financial/animal/edit/:id"} key={"/admin/financial/animal/edit/:id"} element={<Layout64><Route42></Route42></Layout64>}></Route>
<Route path={"/admin/financial/animal/list"} key={"/admin/financial/animal/list"} element={<Layout64><Route43></Route43></Layout64>}></Route>
<Route path={"/admin/financial/earring/add"} key={"/admin/financial/earring/add"} element={<Layout64><Route44></Route44></Layout64>}></Route>
<Route path={"/admin/financial/earring/edit/:id"} key={"/admin/financial/earring/edit/:id"} element={<Layout64><Route45></Route45></Layout64>}></Route>
<Route path={"/admin/financial/earring/list"} key={"/admin/financial/earring/list"} element={<Layout64><Route46></Route46></Layout64>}></Route>
<Route path={"/admin/financial/farm-foods-create"} key={"/admin/financial/farm-foods-create"} element={<Layout64><Route47></Route47></Layout64>}></Route>
<Route path={"/admin/financial/food/add"} key={"/admin/financial/food/add"} element={<Layout64><Route48></Route48></Layout64>}></Route>
<Route path={"/admin/financial/food/edit/:id"} key={"/admin/financial/food/edit/:id"} element={<Layout64><Route49></Route49></Layout64>}></Route>
<Route path={"/admin/financial/food/list"} key={"/admin/financial/food/list"} element={<Layout64><Route50></Route50></Layout64>}></Route>
<Route path={"/admin/financial/gta-create"} key={"/admin/financial/gta-create"} element={<Layout64><Route51></Route51></Layout64>}></Route>
<Route path={"/admin/financial/gta-details/:id"} key={"/admin/financial/gta-details/:id"} element={<Layout64><Route52></Route52></Layout64>}></Route>
<Route path={"/admin/financial/gta-list"} key={"/admin/financial/gta-list"} element={<Layout64><Route53></Route53></Layout64>}></Route>
<Route path={"/admin/financial"} key={"/admin/financial"} element={<Layout64><Route54></Route54></Layout64>}></Route>
<Route path={"/admin/financial/new-order"} key={"/admin/financial/new-order"} element={<Layout64><Route55></Route55></Layout64>}></Route>
<Route path={"/admin/financial/origins/add"} key={"/admin/financial/origins/add"} element={<Layout64><Route56></Route56></Layout64>}></Route>
<Route path={"/admin/financial/origins/edit/:id"} key={"/admin/financial/origins/edit/:id"} element={<Layout64><Route57></Route57></Layout64>}></Route>
<Route path={"/admin/financial/origins/list"} key={"/admin/financial/origins/list"} element={<Layout64><Route58></Route58></Layout64>}></Route>
<Route path={"/admin/financial/stock-add"} key={"/admin/financial/stock-add"} element={<Layout64><Route59></Route59></Layout64>}></Route>
<Route path={"/admin/financial/stock-list"} key={"/admin/financial/stock-list"} element={<Layout64><Route60></Route60></Layout64>}></Route>
<Route path={"/admin/financial/styles"} key={"/admin/financial/styles"} element={<Layout64><Route61></Route61></Layout64>}></Route>
<Route path={"/admin/forecast"} key={"/admin/forecast"} element={<Layout64><Route62></Route62></Layout64>}></Route>
<Route path={"/admin"} key={"/admin"} element={<Layout64><Route63></Route63></Layout64>}></Route>
<Route path={"/admin/management/batch-active/:id"} key={"/admin/management/batch-active/:id"} element={<Layout64><Route65></Route65></Layout64>}></Route>
<Route path={"/admin/management/batch-active"} key={"/admin/management/batch-active"} element={<Layout64><Route66></Route66></Layout64>}></Route>
<Route path={"/admin/management/batch-closing-edit/:id"} key={"/admin/management/batch-closing-edit/:id"} element={<Layout64><Route67></Route67></Layout64>}></Route>
<Route path={"/admin/management/batch-closing-list"} key={"/admin/management/batch-closing-list"} element={<Layout64><Route68></Route68></Layout64>}></Route>
<Route path={"/admin/management/batch-edit/:id"} key={"/admin/management/batch-edit/:id"} element={<Layout64><Route69></Route69></Layout64>}></Route>
<Route path={"/admin/management/batches"} key={"/admin/management/batches"} element={<Layout64><Route70></Route70></Layout64>}></Route>
<Route path={"/admin/management/closed-list"} key={"/admin/management/closed-list"} element={<Layout64><Route71></Route71></Layout64>}></Route>
<Route path={"/admin/management/closures/list/details/:id"} key={"/admin/management/closures/list/details/:id"} element={<Layout64><Route72></Route72></Layout64>}></Route>
<Route path={"/admin/management/closures/list"} key={"/admin/management/closures/list"} element={<Layout64><Route73></Route73></Layout64>}></Route>
<Route path={"/admin/management/closures/new"} key={"/admin/management/closures/new"} element={<Layout64><Route74></Route74></Layout64>}></Route>
<Route path={"/admin/management/closures/trucks/details/:id"} key={"/admin/management/closures/trucks/details/:id"} element={<Layout64><Route75></Route75></Layout64>}></Route>
<Route path={"/admin/management/closures/trucks"} key={"/admin/management/closures/trucks"} element={<Layout64><Route76></Route76></Layout64>}></Route>
<Route path={"/admin/management/execute/entry/:id"} key={"/admin/management/execute/entry/:id"} element={<Layout64><Route77></Route77></Layout64>}></Route>
<Route path={"/admin/management/execute/identification/:id"} key={"/admin/management/execute/identification/:id"} element={<Layout64><Route78></Route78></Layout64>}></Route>
<Route path={"/admin/management/execute"} key={"/admin/management/execute"} element={<Layout64><Route79></Route79></Layout64>}></Route>
<Route path={"/admin/management/execute/sale/:id"} key={"/admin/management/execute/sale/:id"} element={<Layout64><Route80></Route80></Layout64>}></Route>
<Route path={"/admin/management/execute/weighing/:id"} key={"/admin/management/execute/weighing/:id"} element={<Layout64><Route81></Route81></Layout64>}></Route>
<Route path={"/admin/management"} key={"/admin/management"} element={<Layout64><Route82></Route82></Layout64>}></Route>
<Route path={"/admin/management/new"} key={"/admin/management/new"} element={<Layout64><Route83></Route83></Layout64>}></Route>
<Route path={"/admin/medical-services"} key={"/admin/medical-services"} element={<Layout64><Route84></Route84></Layout64>}></Route>
<Route path={"/admin/medical-services/medicines/add"} key={"/admin/medical-services/medicines/add"} element={<Layout64><Route85></Route85></Layout64>}></Route>
<Route path={"/admin/medical-services/medicines/apply"} key={"/admin/medical-services/medicines/apply"} element={<Layout64><Route86></Route86></Layout64>}></Route>
<Route path={"/admin/medical-services/medicines/edit/:id"} key={"/admin/medical-services/medicines/edit/:id"} element={<Layout64><Route87></Route87></Layout64>}></Route>
<Route path={"/admin/medical-services/medicines/list"} key={"/admin/medical-services/medicines/list"} element={<Layout64><Route88></Route88></Layout64>}></Route>
<Route path={"/admin/medical-services/mortality/add"} key={"/admin/medical-services/mortality/add"} element={<Layout64><Route89></Route89></Layout64>}></Route>
<Route path={"/admin/medical-services/mortality/edit/:id"} key={"/admin/medical-services/mortality/edit/:id"} element={<Layout64><Route90></Route90></Layout64>}></Route>
<Route path={"/admin/medical-services/mortality/list"} key={"/admin/medical-services/mortality/list"} element={<Layout64><Route91></Route91></Layout64>}></Route>
<Route path={"/admin/medical-services/protocols/add"} key={"/admin/medical-services/protocols/add"} element={<Layout64><Route92></Route92></Layout64>}></Route>
<Route path={"/admin/medical-services/protocols/details/:id"} key={"/admin/medical-services/protocols/details/:id"} element={<Layout64><Route93></Route93></Layout64>}></Route>
<Route path={"/admin/medical-services/protocols/list"} key={"/admin/medical-services/protocols/list"} element={<Layout64><Route94></Route94></Layout64>}></Route>
<Route path={"/admin/reports/analytical-report"} key={"/admin/reports/analytical-report"} element={<Layout64><Route95></Route95></Layout64>}></Route>
<Route path={"/admin/reports/batches-history"} key={"/admin/reports/batches-history"} element={<Layout64><Route96></Route96></Layout64>}></Route>
<Route path={"/admin/reports/batches-reports"} key={"/admin/reports/batches-reports"} element={<Layout64><Route97></Route97></Layout64>}></Route>
<Route path={"/admin/reports/diet-preparation-efficiency"} key={"/admin/reports/diet-preparation-efficiency"} element={<Layout64><Route98></Route98></Layout64>}></Route>
<Route path={"/admin/reports/economic-batches-reports"} key={"/admin/reports/economic-batches-reports"} element={<Layout64><Route99></Route99></Layout64>}></Route>
<Route path={"/admin/reports"} key={"/admin/reports"} element={<Layout64><Route100></Route100></Layout64>}></Route>
<Route path={"/admin/reports/individual-cattle-closing"} key={"/admin/reports/individual-cattle-closing"} element={<Layout64><Route101></Route101></Layout64>}></Route>
<Route path={"/admin/reports/pastures-reports"} key={"/admin/reports/pastures-reports"} element={<Layout64><Route102></Route102></Layout64>}></Route>
<Route path={"/admin/reports/stock-farm-history"} key={"/admin/reports/stock-farm-history"} element={<Layout64><Route103></Route103></Layout64>}></Route>
<Route path={"/admin/reports/stock-history"} key={"/admin/reports/stock-history"} element={<Layout64><Route104></Route104></Layout64>}></Route>
<Route path={"/admin/reports/stock-reports"} key={"/admin/reports/stock-reports"} element={<Layout64><Route105></Route105></Layout64>}></Route>
<Route path={"/admin/reports/styles"} key={"/admin/reports/styles"} element={<Layout64><Route106></Route106></Layout64>}></Route>
<Route path={"/admin/reports/tract-efficiency-reports"} key={"/admin/reports/tract-efficiency-reports"} element={<Layout64><Route107></Route107></Layout64>}></Route>
<Route path={"/admin/reports/zootechnical-indices"} key={"/admin/reports/zootechnical-indices"} element={<Layout64><Route108></Route108></Layout64>}></Route>
<Route path={"/admin/traceability/bnd/import"} key={"/admin/traceability/bnd/import"} element={<Layout64><Route109></Route109></Layout64>}></Route>
<Route path={"/admin/traceability/bnd/list"} key={"/admin/traceability/bnd/list"} element={<Layout64><Route110></Route110></Layout64>}></Route>
<Route path={"/admin/traceability/certifier/import"} key={"/admin/traceability/certifier/import"} element={<Layout64><Route111></Route111></Layout64>}></Route>
<Route path={"/admin/traceability/communique/change-characteristic/edit/:id"} key={"/admin/traceability/communique/change-characteristic/edit/:id"} element={<Layout64><Route112></Route112></Layout64>}></Route>
<Route path={"/admin/traceability/communique/change-characteristic"} key={"/admin/traceability/communique/change-characteristic"} element={<Layout64><Route113></Route113></Layout64>}></Route>
<Route path={"/admin/traceability/communique/death/edit/:id"} key={"/admin/traceability/communique/death/edit/:id"} element={<Layout64><Route114></Route114></Layout64>}></Route>
<Route path={"/admin/traceability/communique/death"} key={"/admin/traceability/communique/death"} element={<Layout64><Route115></Route115></Layout64>}></Route>
<Route path={"/admin/traceability/communique/entry/emit/:id"} key={"/admin/traceability/communique/entry/emit/:id"} element={<Layout64><Route116></Route116></Layout64>}></Route>
<Route path={"/admin/traceability/communique/entry"} key={"/admin/traceability/communique/entry"} element={<Layout64><Route117></Route117></Layout64>}></Route>
<Route path={"/admin/traceability/communique/exit"} key={"/admin/traceability/communique/exit"} element={<Layout64><Route118></Route118></Layout64>}></Route>
<Route path={"/admin/traceability/communique/overview"} key={"/admin/traceability/communique/overview"} element={<Layout64><Route119></Route119></Layout64>}></Route>
<Route path={"/admin/traceability/communique/re-identification/edit/:id"} key={"/admin/traceability/communique/re-identification/edit/:id"} element={<Layout64><Route120></Route120></Layout64>}></Route>
<Route path={"/admin/traceability/communique/re-identification"} key={"/admin/traceability/communique/re-identification"} element={<Layout64><Route121></Route121></Layout64>}></Route>
<Route path={"/admin/traceability/communique/unusability/edit/:id"} key={"/admin/traceability/communique/unusability/edit/:id"} element={<Layout64><Route122></Route122></Layout64>}></Route>
<Route path={"/admin/traceability/communique/unusability"} key={"/admin/traceability/communique/unusability"} element={<Layout64><Route123></Route123></Layout64>}></Route>
<Route path={"/admin/traceability/earring/details/:id"} key={"/admin/traceability/earring/details/:id"} element={<Layout64><Route124></Route124></Layout64>}></Route>
<Route path={"/admin/traceability/earring/list"} key={"/admin/traceability/earring/list"} element={<Layout64><Route125></Route125></Layout64>}></Route>
<Route path={"/admin/traceability"} key={"/admin/traceability"} element={<Layout64><Route126></Route126></Layout64>}></Route>
<Route path={"/admin/traceability/ox-search"} key={"/admin/traceability/ox-search"} element={<Layout64><Route127></Route127></Layout64>}></Route>
<Route path={"/admin/traceability/spreadsheets/birth"} key={"/admin/traceability/spreadsheets/birth"} element={<Layout64><Route128></Route128></Layout64>}></Route>
<Route path={"/admin/traceability/spreadsheets/entry"} key={"/admin/traceability/spreadsheets/entry"} element={<Layout64><Route129></Route129></Layout64>}></Route>
<Route path={"/admin/traceability/spreadsheets/identification"} key={"/admin/traceability/spreadsheets/identification"} element={<Layout64><Route130></Route130></Layout64>}></Route>
<Route path={"/admin/tract/adjust-dry-matter"} key={"/admin/tract/adjust-dry-matter"} element={<Layout64><Route131></Route131></Layout64>}></Route>
<Route path={"/admin/tract/beat-add"} key={"/admin/tract/beat-add"} element={<Layout64><Route132></Route132></Layout64>}></Route>
<Route path={"/admin/tract/beat-history"} key={"/admin/tract/beat-history"} element={<Layout64><Route133></Route133></Layout64>}></Route>
<Route path={"/admin/tract/beat-of-day"} key={"/admin/tract/beat-of-day"} element={<Layout64><Route134></Route134></Layout64>}></Route>
<Route path={"/admin/tract/change-corral-diet"} key={"/admin/tract/change-corral-diet"} element={<Layout64><Route135></Route135></Layout64>}></Route>
<Route path={"/admin/tract/change-diet"} key={"/admin/tract/change-diet"} element={<Layout64><Route136></Route136></Layout64>}></Route>
<Route path={"/admin/tract/diet-transition-add"} key={"/admin/tract/diet-transition-add"} element={<Layout64><Route137></Route137></Layout64>}></Route>
<Route path={"/admin/tract/diet-transition-add/styles"} key={"/admin/tract/diet-transition-add/styles"} element={<Layout64><Route138></Route138></Layout64>}></Route>
<Route path={"/admin/tract/diet-transition-list"} key={"/admin/tract/diet-transition-list"} element={<Layout64><Route139></Route139></Layout64>}></Route>
<Route path={"/admin/tract/impression-sheets"} key={"/admin/tract/impression-sheets"} element={<Layout64><Route140></Route140></Layout64>}></Route>
<Route path={"/admin/tract"} key={"/admin/tract"} element={<Layout64><Route141></Route141></Layout64>}></Route>
<Route path={"/admin/tract/management-trough/v2"} key={"/admin/tract/management-trough/v2"} element={<Layout64><Route142></Route142></Layout64>}></Route>
<Route path={"/admin/tract/occurrences-create"} key={"/admin/tract/occurrences-create"} element={<Layout64><Route143></Route143></Layout64>}></Route>
<Route path={"/admin/tract/occurrences-create/styles"} key={"/admin/tract/occurrences-create/styles"} element={<Layout64><Route144></Route144></Layout64>}></Route>
<Route path={"/admin/tract/occurrences-list"} key={"/admin/tract/occurrences-list"} element={<Layout64><Route145></Route145></Layout64>}></Route>
<Route path={"/admin/tract/pasture-supplement"} key={"/admin/tract/pasture-supplement"} element={<Layout64><Route146></Route146></Layout64>}></Route>
<Route path={"/admin/tract/pasture-supplement/styles"} key={"/admin/tract/pasture-supplement/styles"} element={<Layout64><Route147></Route147></Layout64>}></Route>
<Route path={"/admin/tract/planning-add"} key={"/admin/tract/planning-add"} element={<Layout64><Route148></Route148></Layout64>}></Route>
<Route path={"/admin/tract/planning-edit/:id"} key={"/admin/tract/planning-edit/:id"} element={<Layout64><Route149></Route149></Layout64>}></Route>
<Route path={"/admin/tract/planning-list"} key={"/admin/tract/planning-list"} element={<Layout64><Route150></Route150></Layout64>}></Route>
<Route path={"/admin/tract/replace-planning"} key={"/admin/tract/replace-planning"} element={<Layout64><Route151></Route151></Layout64>}></Route>
<Route path={"/admin/tract/replicate"} key={"/admin/tract/replicate"} element={<Layout64><Route152></Route152></Layout64>}></Route>
<Route path={"/admin/tract/tract-of-day"} key={"/admin/tract/tract-of-day"} element={<Layout64><Route153></Route153></Layout64>}></Route>
<Route path={"/admin/tract/tract-of-day/styles"} key={"/admin/tract/tract-of-day/styles"} element={<Layout64><Route154></Route154></Layout64>}></Route>
<Route path={"/admin/tract/tract-of-day/v2"} key={"/admin/tract/tract-of-day/v2"} element={<Layout64><Route155></Route155></Layout64>}></Route>
<Route path={"/create-account"} key={"/create-account"} element={<Route156></Route156>}></Route>
<Route path={"/"} key={"/"} element={<Route157></Route157>}></Route>
<Route path={"/login"} key={"/login"} element={<Route158></Route158>}></Route>
<Route path={"/login/styles"} key={"/login/styles"} element={<Route159></Route159>}></Route>
<Route path={"/privacy"} key={"/privacy"} element={<Route160></Route160>}></Route>
          <Route
            path="*"
            element={props.notFoundPage || <div>404</div>}
          />
        </Routes>
      </Suspense> 
    </BrowserRouter>
  );
}